.containerUploadPhone {
  display: flex;
  flex-direction: column;
  width: 35rem;
  margin: 2% auto;
  box-shadow: 0px 5px 10px gray;
  border-radius: 15px;
  padding: 3%;
}

.modalUploadPhone {
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
}

.modalUploadPhone-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}

.closeUploadPhone {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.closeUploadPhone:hover {
  color: black;
}
